<template>
  <s-card class="stats-card pa-4 pa-lg-6 overflow-hidden" style="height: 100%">
    <div class="d-flex justify-space-between align-center">
      <s-text weight="medium" size="md-m">
        {{ title }}
      </s-text>
      <div class="d-flex justify-end align-center">
        <s-btn
          icon
          depressed
          style="min-height: 40px !important"
          class="mr-2"
          @click="expand()"
        >
          <v-icon>mdi-format-list-bulleted-square</v-icon>
        </s-btn>
      </div>
    </div>

    <div class="d-flex content">
      <v-progress-circular
        v-if="isWorking"
        indeterminate
        class="ma-auto"
        color="primary"
        :size="20"
        :width="3"
      />
      <distribution-analytics-generic
        v-else
        v-bind="$attrs"
        class="mt-10"
        :chart-data="chartData"
        @click="
          (data) => {
            $emit('click', data)
          }
        "
      />
    </div>
  </s-card>
</template>

<script>
import DistributionAnalyticsGeneric from '@/components/cards/DistributionAnalyticsGeneric'
export default {
  name: 'PieCard',
  components: {
    'distribution-analytics-generic': DistributionAnalyticsGeneric,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    chartData: {
      type: Array,
      default: () => [],
    },
    _key: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isWorking: false,
    }
  },
  methods: {
    expand() {
      this.$emit('expand', {
        title: this.title,
        getter: `analytics/${this._key}`,
      })
    },
  },
}
</script>

<style scoped>
.content {
  width: 100%;
  min-height: 290px;
}
</style>
