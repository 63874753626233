const isEmpty = (value) => {
  if (typeof value === 'string') return value.length === 0

  if (Array.isArray(value)) return value.length === 0

  if (typeof value === 'object' && value !== null) {
    return Object.keys(value).length === 0
  }

  return true
}

export default isEmpty
