<template>
  <s-card class="stats-card pa-4 pa-lg-6 overflow-hidden">
    <div class="d-flex justify-space-between align-center">
      <s-text weight="bold" size="md-m">Apps</s-text>
    </div>

    <div class="d-flex align-center mt-4" v-if="!isWorking">
      <v-row>
        <v-col cols="4">
          <s-text weight="medium" size="md" color="gray">Proxies</s-text>
          <s-text weight="bold" size="m-lg" color="primary">
            <ICountUp
              :delay="countup.delay"
              :endVal="totalProxies"
              :options="countup.options"
            />
          </s-text>
        </v-col>
        <v-col cols="4">
          <s-text weight="medium" size="md" color="gray"> Apps </s-text>
          <s-text weight="bold" size="m-lg" color="primary">
            <ICountUp
              :delay="countup.delay"
              :endVal="totalApps"
              :options="countup.options"
            />
          </s-text>
        </v-col>
      </v-row>
    </div>

    <div v-else class="d-flex pa-12">
      <v-progress-circular
        indeterminate
        class="ma-auto"
        color="primary"
        :size="20"
        :width="3"
      />
    </div>
  </s-card>
</template>

<script>
import { mapGetters } from 'vuex'
import ICountUp from 'vue-countup-v2'

export default {
  name: 'TopStatsCard',
  components: {
    ICountUp,
  },
  computed: {
    ...mapGetters({
      apps: 'app/apps',
      proxies: 'edge/apps',
    }),
    totalApps() {
      return this.apps ? this.apps.length : 0
    },
    totalProxies() {
      return this.proxies ? this.proxies.length : 0
    },
  },
  data() {
    return {
      isWorking: false,
      countup: {
        delay: 500,
        options: {
          useEasing: true,
          useGrouping: true,
          separator: ',',
          decimal: '.',
        },
      },
    }
  },
  methods: {
    getApps() {
      this.$store.dispatch('app/getApps')
    },
    getProxies() {
      this.$store.dispatch('edge/getApps')
    },
  },
  watch: {
    apps: {
      handler() {
        if (this.apps) return
        this.getApps()
      },
      immediate: true,
    },
    proxies: {
      handler() {
        if (this.proxies) return
        this.getProxies()
      },
      immediate: true,
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';

.stats-card {
  border-bottom: $thin-borderline;
}

.stats-card__referesh-btn {
  font-size: $text-sm !important;
}

.stats-card__header {
  cursor: default;
}
</style>
