<template>
  <v-container>
    <v-row>
      <v-col>
        <top-stats-card />
      </v-col>
    </v-row>
    <v-row class="align-stretch">
      <v-col cols="12">
        <bar-card
          :title="'Hourly Data Usage'"
          :chart-data="metricsByHourlyDataUsage"
        />
      </v-col>
    </v-row>
    <v-row class="align-stretch">
      <v-col cols="6">
        <pie-card
          :title="'Data Usage By Telco'"
          :chart-data="metricsByTelco"
          :_key="'metricsByTelco'"
          @expand="showListDialog"
        />
      </v-col>
      <v-col cols="6">
        <pie-card
          :title="'Data Usage By FQDN'"
          :chart-data="metricsByFQDN"
          :_key="'metricsByFQDN'"
          @expand="showListDialog"
        />
      </v-col>
    </v-row>
    <v-row class="mb-10 align-stretch">
      <v-col cols="12">
        <s-card
          class="stats-card pa-4 pa-lg-6 overflow-hidden"
          style="height: 100%"
        >
          <div class="d-flex justify-space-between align-center">
            <s-text weight="medium" size="md-m"> Top Data Users </s-text>
          </div>
          <analytic-distribution-list :getter="'analytics/metricsByMSISDN'" />
        </s-card>
      </v-col>
    </v-row>
    <s-dialog
      v-model="showDialog"
      persistent
      isModal
      :title="modalTitle"
      :close-default="modalCloseDafaultSettings"
      @close="showListModal = false"
    >
      <analytic-distribution-list
        v-if="showListModal"
        class="px-8"
        :getter="getter"
      />
    </s-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import TopStatsCard from '@/components/cards/TopStatsCard'
import PieCard from '@/components/cards/PieCard'
import AnalyticDistributionList from '@/views/dashboard/AnalyticDistributionList'
import BarCard from '@/components/cards/BarCard'
export default {
  components: {
    'top-stats-card': TopStatsCard,
    'pie-card': PieCard,
    'analytic-distribution-list': AnalyticDistributionList,
    'bar-card': BarCard,
  },
  computed: {
    ...mapGetters({
      metricsByTelco: 'analytics/metricsByTelco',
      metricsByFQDN: 'analytics/metricsByFQDN',
      metricsByHourlyDataUsage: 'analytics/metricsByHourlyDataUsage',
      phoneInstalledAppsChartData: 'analytics/phoneInstalledApps',
      phoneModelsChartData: 'analytics/phoneModels',
      phoneBrandsChartData: 'analytics/phoneBrands',
    }),
    showDialog() {
      return this.showListModal || !!this.showModal
    },
    modalCloseDafaultSettings() {
      return !this.showListModal
    },
    modalTitle() {
      return this.title ? this.title : ''
    },
  },
  data() {
    return {
      showListModal: false,
      title: '',
      getter: null,
    }
  },
  methods: {
    getAnalytics() {
      this.isWorking = true
      this.$store.dispatch('analytics/getAnalytics').finally(() => {
        this.isWorking = false
      })
    },
    showListDialog({ title, getter }) {
      this.title = title
      this.getter = getter
      this.showListModal = true
    },
    getPhoneInstalledAppsAnalytics() {
      this.isWorking = true
      this.$store.dispatch('analytics/getPhoneInstalledApps').finally(() => {
        this.isWorking = false
      })
    },
    getPhoneModelsAnalytics() {
      this.isWorking = true
      this.$store.dispatch('analytics/getPhoneModels').finally(() => {
        this.isWorking = false
      })
    },
    getPhoneBrandsAnalytics() {
      this.isWorking = true
      this.$store.dispatch('analytics/getPhoneBrands').finally(() => {
        this.isWorking = false
      })
    },
  },
  mounted() {
    this.getAnalytics()
  },
  destroyed() {
    this.$store.dispatch('analytics/clear')
  },
}
</script>

<style lang="scss" scoped></style>
