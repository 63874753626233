<template>
  <s-card class="pa-0">
    <div
      class="d-flex justify-space-between align-center bottom-thin-border pb-4 pa-6"
    >
      <s-text weight="bold" size="md-m">
        {{ title }}
      </s-text>
    </div>

    <section class="pl-6 pr-6 pt-4 pb-6" v-if="!isWorking">
      <horizontal-bar-chart
        v-if="hasHourlyChartData"
        :chart-data="computedChartData"
        v-bind:style="{ height: graphHeight }"
      />

      <empty-state
        v-if="!hasHourlyChartData"
        message="No data available"
        hint="Please check back."
        style="height: 350px"
        class="ma-auto"
      />
    </section>

    <div class="d-flex pa-10 ma-10" style="height: 60px" v-else>
      <v-progress-circular
        indeterminate
        class="ma-auto"
        color="primary"
        :size="20"
        :width="3"
      />
    </div>
  </s-card>
</template>

<script>
import HorizontalBarChart from '@/components/charts/HorizontalBarChart'
import EmptyState from '@/components/cards/EmptyState'
import { convertBytesToGb } from '@/utils/utils'
import { getHumanReadableDate } from '@/utils/date'
import isEmpty from '@/utils/isEmpty'

export default {
  name: 'RangeAnalytics',
  components: { HorizontalBarChart, EmptyState },
  props: {
    title: {
      type: String,
      required: true,
    },
    chartData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      SINGLE_GRAPH_HEIGHT: 50,
      isWorking: false,
    }
  },
  computed: {
    hasHourlyChartData: function () {
      return !this.isChartDataEmpty && !this.isComputedChartDataEmpty
    },
    isChartDataEmpty: function () {
      return isEmpty(this.chartData)
    },
    isComputedChartDataEmpty: function () {
      const dataset = Object.assign([], this.chartData)
      const totalTxRx = dataset.reduce(
        (acc, data) => acc + (data.tx + data.rx),
        0
      )
      return totalTxRx === 0
    },
    graphHeight: function () {
      return `${
        Object.keys(this.chartData).slice(0, 10).length *
        this.SINGLE_GRAPH_HEIGHT
      }px`
    },
    computedChartData: function () {
      const dataset = Object.assign([], this.chartData)

      const newLabels = dataset.map((object) =>
        getHumanReadableDate(object.time, 'HH:mm')
      )
      const rxDataSet = dataset.map((object) => convertBytesToGb(object.rx))
      const txDataSet = dataset.map((object) => convertBytesToGb(object.tx))

      const computedChartData = {
        labels: newLabels,
        datasets: [
          {
            label: 'Rx',
            backgroundColor: '#ff9900',
            barPercentage: 1,
            maxBarThickness: 25,
            categoryPercentage: 0.95,
            data: rxDataSet,
          },
          {
            label: 'Tx',
            backgroundColor: '#0099ff',
            barPercentage: 1,
            maxBarThickness: 25,
            categoryPercentage: 0.95,
            data: txDataSet,
          },
        ],
      }

      return computedChartData
    },
  },
  methods: {
    getHumanReadableDate,
  },
}
</script>
