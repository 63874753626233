<script>
import { Bar } from 'vue-chartjs'

export default {
  name: 'HorizontalBarChart',
  mixins: [Bar],
  props: {
    chartData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: false,
        scales: {
          xAxes: [
            {
              stacked: true,
              gridLines: {
                drawBorder: false,
                color: 'rgba(0, 0, 0, 0.05)',
              },
            },
          ],
          yAxes: [
            {
              stacked: true,
              gridLines: {
                display: false,
              },
              ticks: {
                fontSize: 13,
                fontStyle: 600,
                fontColor: '#333',
                fontFamily: 'Questrial, sans-serif',
              },
            },
          ],
        },
      },
    }
  },
  mounted() {
    this.renderChart(this.chartData, this.options)
  },
  watch: {
    chartData: {
      handler(newChartData) {
        if (this.chartData === newChartData) return

        this.renderChart(newChartData, this.options)
      },
    },
  },
}
</script>
